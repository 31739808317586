import React from 'react'
import {connect} from 'react-redux'
import {NavLink, withRouter} from 'react-router-dom'
import {StatusCell} from '../cell/StatusCell'
import {HOME_PATH} from '../../../../../configs'
import {LifeCenterUserTableRow} from '../../../api/model/LifeCenterUserTableView'
import Switch from '../../../../../uiKit/Switch'
import classes from './styles.module.scss'
import {openUserAccessModal, openUserDeleteModal} from '../../../actions/contactsModalsAction'
import {DeleteIcon} from '../../../../../uiKit/icons/Icons'

interface ContactsTableBodyProps {
  match: any
  contacts: LifeCenterUserTableRow[]
}

const ContactsTableBody: React.FC<ContactsTableBodyProps> = ({match, contacts}) => {

  const preparePhoneNumber = (phoneNumber) => {
    return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`
  }

  const getRowStyle = (index, c: LifeCenterUserTableRow) => {
    if (c.statusTitle == 'User Assigned') {
      return {background: 'rgba(238, 238, 238)'}
    } else {
      if ((index + 1) % 2 === 1) {
        return {background: 'rgba(90, 152, 247, 0.1)'}
      } else {
        return {background: '#F7FAFE'}
      }
    }
  }

  const onChangeHandler = (isActive: boolean, contact: LifeCenterUserTableRow) => {
    if (contact.statusTitle == 'Access removed') {
    } else {
      openUserAccessModal(isActive, contact.id)
    }
  }

  const deleteUserHandler = (isActive: boolean, contact: LifeCenterUserTableRow) => {
    openUserDeleteModal(isActive, contact.id)
  }

  const handleClick = (e) => {
    if (e.target.type === 'checkbox'
        || e.target.dataset.customAttribute === 'delete') {
      e.preventDefault()
    }
  }

  return (
    <>
      {contacts != null && contacts
        .map((contact, index) => (
          <NavLink
            to={`${HOME_PATH}/bot/${match.params.botId}/contacts/${contact.id}`}
            key={index}
            style={getRowStyle(index, contact)}
            className={classes.contactItem}
            onClick={handleClick}
          >
            <div className={classes.firstAndLastName}>{contact.firstAndLastName}</div>
            <div className={classes.userName}>{contact.userName}</div>
            <div className={classes.email}>{contact.email}</div>
            <div className={classes.phone}>{contact.phoneNumber ? preparePhoneNumber(contact.phoneNumber) : 'NONE'}</div>
            <div className={classes.numberOfLogins}>{contact.numberOfLogins ? contact.numberOfLogins : '0'}</div>
            <div className={classes.totalInteractionTime}>{contact.totalInteractionTime}</div>
            <div className={classes.status}>
              <StatusCell
                statusTitle={contact.statusTitle}
                statusColor={contact.statusColor}
              />
            </div>
            <div className={classes.access}>
              <Switch
                checked={contact.active}
                onChange={() => onChangeHandler(contact.active, contact)}
              />
            </div>
            <div className={classes.delete} data-custom-attribute='delete' onClick={() => deleteUserHandler(contact.active, contact)}>
              <DeleteIcon/>
            </div>
          </NavLink>
        ))}
    </>
  )
}

const mapStateToProps = state => ({
  contacts: state.contacts
})

export default withRouter(connect(mapStateToProps)(ContactsTableBody))
