/* tslint:disable */
/* eslint-disable */
import moment from "moment-timezone";

export enum ResultStatus {
    NEGATIVE = 'NEGATIVE',
    VUS = 'VUS',
    POSITIVE = 'POSITIVE'
}

export enum GtKitOption {
    CONFIRMED = 'CONFIRMED',
    REJECTED = 'REJECTED'
}

export interface InviteObject {
    sender: string;
    linkSender: string;
    linkRecipient: string;
    recipient: string;
    recipientId: number;
    iteration: number;
    link: string;
}

export enum ScheduleResultFileType {
  LAB_RESULTS = 'labResults',
  FAMILY_COMMUNICATION_LETTER = 'familyCommunicationLetter',
  GT_RESULTS_LETTER = 'gtResultsLetter'
}

export interface ContactInfo {
    firstAndLastName: string;
    username: string;
    email: string;
    phone: string;
    recruitmentLocation: string;
    riskAssessmentQuestionnaireLink?: string;
    familyPedigreeQuestionnaireLink?: string;
    familyTreeLink?: string;
    readinessRulerLink?: string;
    nameFromArguments?: string;
    surnameFromArguments?: string;
    emailFromArguments?: string;
    phoneFromArguments?: string;
    addressFromArguments?: string;
    resultsDeliverWay?: string;
    insuranceCardPhotos?: string[];
    isRequiredStatus: boolean;
    activationLink?: string;
    readinessRulerXLSXLink: string;
    familyPedigreeQuestionnaireXLSXLink: string;
    inviteDTOS?: InviteObject[];
    isFullListRelation?: string;
}

export interface GtKitInfo {
    gtKitOption: GtKitOption
}

export interface ResultsDeliveryInfo {
    isResultsDeliveryScheduled: boolean;
    resultStatus: ResultStatus;
    isResultsDeliveryConfirmed: boolean
    resultsDeliveryConfirmationTimestamp: string;
    positiveResultsDeliveryConfirmationTimestamp: string;
    rescheduleResultDelivery: boolean
}

export const ScheduleResultTexts = {
  labResultsTitle: 'Select a PDF with lab results or drag and drop here',
  labResultsSubTile: 'For NEGATIVE, POSITIVE and VUS results',
  gtResultsLetterTitle: 'Select a PDF with GT Results Letter or drag and drop here',
  gtResultsLetterSubTitle: 'Those with NEGATIVE or VUS results receive a standard letter\n\n' + 'Those with POSITIVE results receive a tailored letter after a disclosure session with the GC',
  familyCommunicationLetterTitle: 'Select a PDF with Family Communication Letter or drag and drop here',
  familyCommunicationLetterSubTitle: 'For NEGATIVE, POSITIVE and VUS results'
}

export interface RescheduleResultDeliveryBody {
  resultDeliveryDate: moment.Moment,
  resultDeliveryTime: string
}
