import React, { FC } from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import Modal from '../../../../uiKit/Modal'
import SubmitButton from '../../../../uiKit/buttons/SubmitButton'
import {closeUserDeleteModal} from '../../actions/contactsModalsAction'
import classes from '../../styles.module.scss'
import {deleteUser} from '../../api/contactsApi'

interface Props {
    deleteUserModalState: {
        isDeleteUserModalOpen: boolean
        contactId?: number
    },
    contactsPage: number,
    match: any
}

const DeleteUserModal: FC<Props> = ({ deleteUserModalState, contactsPage, match }) => {
  const { isDeleteUserModalOpen, contactId } = deleteUserModalState

  const onClickHandler = () => {
    deleteUser(match.params.botId, contactId, contactsPage || 0)
  }

  return (
    <Modal
      classes={{paper: classes.accessModal}}
      open={isDeleteUserModalOpen}
      onClose={closeUserDeleteModal}
    >
      <div className={classes.accessText}>
                Are you sure you want to delete this user?
      </div>
      <div className={classes.accessButtonContainer}>
        <SubmitButton
          disabled={false}
          empty={true}
          styles={{
            width: '154px',
            height: '48px',
            marginRight: '8px',
            border: 'none'
          }}
          onClick={closeUserDeleteModal}
          title={'Back'}
        />
        <SubmitButton
          disabled={false}
          styles={{
            width: '154px',
            height: '48px',
          }}
          onClick={onClickHandler}
          title={'Delete user'}
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = state => ({
  contactsSize: state.contactsSize,
  contactsPage: state.contactsPage,
  deleteUserModalState: state.deleteUserModalState,
})

export default withRouter(connect(mapStateToProps)(DeleteUserModal))
