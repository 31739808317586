import React, {useRef, useState} from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {ChatBotDeliverablesBlock} from './ChatBotDeliverablesBlock'
import classes from './styles.module.scss'
import {ContactInfo} from '../../../api/model/ContactInfo'
import {CustomButton} from '../../common/CustomButton'
import {FamilyShareTreeView} from './tree_view/FamilyShareTreeView'
import FamilyTreeModal from '../../FamilyTreePage/FamilyTreeModal/FamilyTreeModal'
import {getContactInfo} from '../../../api/contactInfoApi'
import {InfoField} from '../../common/InfoField'

interface ChatBotDeliverablesSectionProps {
    contactInfo: ContactInfo
    match: any
}

export interface FamilyTreeSettingsInterface {
    isOpen: boolean
    isLoading: boolean
}

const ChatBotDeliverablesSection: React.FC<ChatBotDeliverablesSectionProps> = ({match, contactInfo}) => {
  const [familyTreeSettings, setFamilyTreeSettings] = useState<FamilyTreeSettingsInterface>({
    isOpen: false,
    isLoading: false
  })
  const reactFlowRef = useRef(null)
  let chatId = ''

  const {
    username,
    familyPedigreeQuestionnaireLink,
    familyTreeLink,
    readinessRulerLink,
    readinessRulerXLSXLink,
    familyPedigreeQuestionnaireXLSXLink,
    inviteDTOS,
    isFullListRelation
  } = contactInfo

  if (familyPedigreeQuestionnaireLink !== null) {
    chatId = new URLSearchParams(new URL(familyPedigreeQuestionnaireLink).search).get('chatId')
  }
  const handleClick = (e) => {
    e.preventDefault()
    setFamilyTreeSettings((prevState) => {
      return {
        ...prevState,
        isOpen: true
      }
    })
    getContactInfo(match.params.botId, match.params.contactId)
  }

  const openPDFHandler = (e, viewUrl: string) => {
    e.preventDefault()
    if (viewUrl) {
      try {
        fetch(viewUrl, {method: 'GET'}).then(res => {
          if (res.ok) {
            return res.blob()
          }
        }).then(res => {
          const fileURL = URL.createObjectURL(res)
          window.open(fileURL, '_blank')
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
  return (
    <div className={classes.deliverablesSectionWrapper}>
      <div className={classes.sectionTitle}>
            Chatbot deliverables
      </div>
      <ChatBotDeliverablesBlock
        title={'Readiness Ruler'}
        viewUrl={readinessRulerLink}
        excelLink={readinessRulerXLSXLink}
        openPDFHandler={openPDFHandler}
        username={username}
        hasDownloadExcelButton={true}
      />
      <ChatBotDeliverablesBlock
        title={'Family Pedigree Questionnaire'}
        viewUrl={familyPedigreeQuestionnaireLink}
        excelLink={familyPedigreeQuestionnaireXLSXLink}
        openPDFHandler={openPDFHandler}
        username={username}
        hasDownloadExcelButton={true}
      />
      <div className={classes.sectionWrapperWithButton}>
        <ChatBotDeliverablesBlock
          title={'Family Tree'}
          viewUrl={familyTreeSettings.isOpen ? null : familyTreeLink}
          openPDFHandler={openPDFHandler}
          hasDownloadExcelButton={false}
        />
        {familyPedigreeQuestionnaireLink && <CustomButton
          text='Renew tree'
          disabled={familyTreeSettings.isOpen}
          fulled={false}
          onBtnClick={handleClick}
        />}
        {familyTreeSettings.isOpen && <FamilyTreeModal
          reactFlowRef={reactFlowRef}
          match={match}
          chatId={chatId}
          setFamilyTreeSettings={setFamilyTreeSettings}
        />}
      </div>
      <br></br>
      {inviteDTOS.length !== 0 &&
            <div>
              <div>Family Sharing Info</div>
              <br></br>
              <FamilyShareTreeView username={username} data={inviteDTOS}>
              </FamilyShareTreeView>
            </div>
      }
      {inviteDTOS.length !== 0 &&
            <InfoField
              title={'All living relatives'}
              value={isFullListRelation ? isFullListRelation : 'NONE'}
              styles={{ marginTop: 20 }}
            />
      }
    </div>
  )
}

const mapStateToProps = state => ({
  contactInfo: state.contactInfo
})

export default withRouter(connect(mapStateToProps)(ChatBotDeliverablesSection))
