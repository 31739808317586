import React, { useEffect } from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {NoContactsData} from './components/table/no_data/NoContactsData'
import {getPagingContacts} from './api/contactsApi'
import {clearContacts} from './actions/contactsAction'
import {ContactsTabHeader} from './components/ContactsTabHeader'
import ContactsTable from './components/table/ContactsTable'
import {closeInviteUserModal} from './actions/contactsModalsAction'
import InviteNewUserModal from './components/modal/InviteNewUserModal'
import UserAccessModal from './components/modal/UserAccessModal'
import DeleteUserModal from './components/modal/DeleteUserModal'
import Modal from '../../uiKit/Modal'
import classes from './styles.module.scss'
import Loader from '../../uiKit/loaders/loader'
import {LifeCenterUserTableView} from './api/model/LifeCenterUserTableView'

interface ContactsProps {
  contacts?: LifeCenterUserTableView
  contactsPage?: number
  contactsSize?: number,
  isInviteNewUserModalOpen: boolean,
  match: any
}

const Contacts: React.FC<ContactsProps> = ({
  contacts,
  contactsPage,
  contactsSize,
  isInviteNewUserModalOpen,
  match
}) => {

  useEffect(() => {
    clearContacts()
    getPagingContacts(contactsPage || 0, match.params.botId)
  }, [contactsPage, match.params.botId])

  if (contacts !== null) {
    return (
      <div style={{overflowY: 'auto', height: '100%'}}>
        <Modal
          classes={{paper: classes.paperModal}}
          open={isInviteNewUserModalOpen}
          onClose={closeInviteUserModal}>
          <InviteNewUserModal/>
        </Modal>
        <UserAccessModal />
        <DeleteUserModal />
        <ContactsTabHeader/>
        {contactsSize ? (
          <ContactsTable/>
        ) : (
          <NoContactsData/>
        )}
      </div>
    )
  } else {
    return <Loader/>
  }
}

const mapStateToProps = state => ({
  contacts: state.contacts,
  contactsPage: state.contactsPage,
  contactsSize: state.contactsSize,
  isInviteNewUserModalOpen: state.isInviteNewUserModalOpen
})

export default withRouter(connect(mapStateToProps)(Contacts))
